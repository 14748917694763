import React from 'react';
import '../components/layout.css';
import { motion } from "framer-motion";
import { useSpring, animated } from 'react-spring';

//images
import boba from '../images/boba.png'

const Single = ({location}) => {

    const colors = {
        boba: '#539C86',
    }

    return (
        <>
            <div className="singleContainer" style={{backgroundColor:colors.boba}}>
                <motion.div 
                    id="boba"
                    initial={{
                        opacity: 0, 
                        scale: 0.3,
                    }}

                    animate={{ 
                        opacity: 1,
                        scale: 1,
                    }}
                    transition={{ duration: 0.5 }}
                />
            </div>
        </>
    )
}

export default Single;